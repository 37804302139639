export const m ={
    head_nav1: 'Shielded Transaction',
    head_nav2: 'Introduction',
    head_nav3: 'Advantage',
    head_nav4: 'FAQ',
    head_nav5: 'Contact us',
    head_nav6: 'Technology',

    index_tab1: 'Deposit',
    index_tab2: 'Withdraw',
    index_deposit_label1: 'Deposit method',
    index_deposit_label2: 'Deposit PIN',
    index_deposit_label3: 'Deposit address',
    index_deposit_hit1: 'Get the Deposit PIN first',
    index_deposit_btn: 'Generate Deposit PIN',
    index_tips: 'Tips',
    index_tip1: '1. A single transaction\'s mini amount ',
    index_tip2: '2. A single transaction\'s default fee ',
    index_tip3: '3. One deposit address only accepts one deposit, and a corresponding deposit PIN is generated, please keep it properly',
    index_tip4: 'Minimum 18 U',

    index_withdraw_label1: 'Withdrawal type',
    index_withdraw_label2: 'Deposit PIN',
    index_withdraw_label3: 'QRcode deposit address',
    index_withdraw_label4: 'Withdrawal amount',
    index_withdraw_label5: 'Receiveing address',
    index_withdraw_hit1: 'Input deposit PIN',
    index_withdraw_hit2: 'Input QRcode deposit address',
    index_withdraw_hit3: 'Take out multiple times',
    index_withdraw_hit4: 'Amount received',
    index_withdraw_hit5: 'Withdrawal amount',
    index_withdraw_hit6: 'Gas fee',
    index_withdraw_hit7: 'Input receiveing address',
    index_withdraw_hit8: 'Amount available ',
    index_withdraw_btn: 'Withdraw',

    error_withdrawid: 'Deposit PIN is wrong',
    error_depositaddr: 'QRcode deposit address is wrong',
    error_amount: 'Withdrawal amount must be greater than zero, higher than the gas fee but not higher than the available amount',
    error_withdrawaddr: 'Receiveing address format is wrong',
    success_withdrawok: 'Withdraw successful',

    intro_nav1: 'How <strong class="green--text">Hide USDT</strong> works',
    intro_nav1_subtitle1: 'Deposit',
    intro_nav1_content1: 'The user generates a pair of random key (PIN) and deposit address (the PIN and the address are in a one-to-one correspondence), and deposits USDT (TRC20), and at the same time submits the hash of the PIN to TRON\'s smart contract anonymously',
    intro_nav1_subtitle2: 'Wait',
    intro_nav1_content2: 'After depositing, users should wait for a while before withdrawing, here we recommend spreading out the funds in multiple batches to improve their privacy.',
    intro_nav1_subtitle3: 'Withdraw',
    intro_nav1_content3: 'The user submits the deposit PIN and the corresponding deposit address to effectively prove the ownership of the funds, and then the anonymous contract will transfer the funds to the designated recipient through TRC20',
    intro_nav2: 'How <strong class="green--text">Hide USDT</strong> achieves privacy',
    intro_nav2_content1: 'Hide USDT improves transaction privacy by breaking the on-chain transfer relationship between source and destination addresses. It uses zero-knowledge proofs, through the TRON decentralized anonymous transaction network, and accepts USDT(TRC20) deposits that can be withdrawn through different addresses. To protect privacy, transaction transfers are completed anonymously using the DPOS consensus mechanism held by the TRON network. Whenever a new address withdraws USDT, there is no way to link the withdrawal with the deposit, thus ensuring complete privacy.',

    adv_nav: 'About <strong class="green--text"> Hide USDT </strong> advantage',
    adv_subtitle1: 'Zero-knowledge Proof',
    adv_content1: 'With advanced zero-knowledge proof on TRON\'s decentralized shielded network, users can anonymously trade tokens on TRON.',
    adv_subtitle2: 'Instant Transfer',
    adv_content2: 'TRON-based DPOS consensus mechanism facilitates swift shielded transfers.',
    adv_subtitle3: 'Untrackable',
    adv_content3: 'With all info regarding on-chain operation hidden, attackers couldn\'t match the on-chain info with specific individuals.',
    adv_subtitle4: 'Easy to Use',
    adv_content4: 'Account information is convenient for users, which can transfer quickly and view transaction records.',

    help_nav: 'FAQ about <strong class="green--text"> Hide USDT </strong>',
    help_title1: 'What is Hide USDT?',
    help_content1: 'Hide USDT is a website that allows shielded transfer tokens on TRON network and leverages zk-snarks to safeguard your assets.',
    help_title2: 'How to use Hide USDT?',
    help_content2: 'Using Hide USDT platform inventory transaction only requires 3 steps: 1. To save and retrieve the assets, you need to use the PIN and the address where the assets are stored. 2. Waiting time (the longer the time, the hidden update). 3. With the asset-backed reputation and asset transfer address, it is possible to prove the location of the asset transfer to the new asset. ',
    help_title3: 'How does Hide USDT ensure anonymity?',
    help_content3: 'Zk-snarks technology is used to ensure anonymity. Users create shielded accounts locally, which makes account info untrackble, and realizes the anonymization of shileded transaction.',
    help_title4: 'Could transfer amount be hided in Hide USDT?',
    help_content4: 'Hide USDT anonymous account information and transfers from anonymous accounts to anonymous accounts are completely anonymous, but the public account and its transfer amount can be checked on the chain. In order to further ensure anonymity, it is recommended that users transfer funds in batches and operate at intervals.',

    contact_nav: 'Contact us',
    contact_title: 'Email',
    contact_msg: 'Contact',
    contact_mailerror: 'E-mail must be valid',
    contact_msgerror: 'Content must be in 1-500 characters'
};