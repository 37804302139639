export const m ={
    head_nav1: '匿名交易',
    head_nav2: '介紹',
    head_nav3: '優勢',
    head_nav4: '問題',
    head_nav5: '聯繫我們',
    head_nav6: '實現原理',

    index_tab1: '存款',
    index_tab2: '取款',
    index_deposit_label1: '存款方式',
    index_deposit_label2: '存款憑證',
    index_deposit_label3: '收款地址',
    index_deposit_hit1: '請先生成存款憑證',
    index_deposit_btn: '生成存款憑證',
    index_tips: '溫馨提示',
    index_tip1: '1. 單筆交易最低限額 ',
    index_tip2: '2. 單筆交易默認手續費為 ',
    index_tip3: '3. 一個收款地址只接受一筆存款，對應生成一個存款憑證，請妥善保存',
    index_tip4: '最低 18 U',

    index_withdraw_label1: '取款方式',
    index_withdraw_label2: '存款憑證',
    index_withdraw_label3: '二維碼存款地址',
    index_withdraw_label4: '取款金額',
    index_withdraw_label5: '接收地址',
    index_withdraw_hit1: '輸入存款憑證',
    index_withdraw_hit2: '輸入二維碼存款地址',
    index_withdraw_hit3: '建議分批多次取出',
    index_withdraw_hit4: '到賬金額',
    index_withdraw_hit5: '取款金額',
    index_withdraw_hit6: '燃料費',
    index_withdraw_hit7: '輸入接收地址',
    index_withdraw_hit8: '可取金額',
    index_withdraw_btn: '我要取款',

    error_withdrawid: '存款憑證錯誤',
    error_depositaddr: '二維碼存款地址錯誤',
    error_amount: '取款金額需大於零,高於燃料費但不高於可取金額',
    error_withdrawaddr: '收款地址格式錯誤',
    success_withdrawok: '取款成功',

    intro_nav1: '<strong class="green--text">Hide USDT</strong> 如何工作',
    intro_nav1_subtitle1: '存款',
    intro_nav1_content1: '用戶生成一對隨機密鑰憑證(PIN)和存款地址(憑證和地址為一一對應關係), 並存入USDT(TRC20), 同時將憑證的哈希通過匿名提交給TRON的智能合約.',
    intro_nav1_subtitle2: '等待',
    intro_nav1_content2: '存款後, 用戶應該等待一段時間才能提款，這裡我們建議分多筆把資金分散取出，以改善他們的隱私.',
    intro_nav1_subtitle3: '取款',
    intro_nav1_content3: '用戶提交存款憑證和對應的存款地址, 有效證明資金歸屬權, 然後匿名合約會通過TRC20將資金轉移給指定的接收者.',
    intro_nav2: '<strong class="green--text">Hide USDT</strong> 如何實現隱私',
    intro_nav2_content1: 'Hide USDT 通過打破源地址和目標地址之間的鏈上轉賬關係接來提高交易隱私. 它使用零知識證明, 通過TRON去中心化匿名交易網絡, 接受可以通過不同地址提取的 USDT(TRC20) 存款. 為了保護隱私, 使用TRON網絡持有的DPOS共識機制, 匿名完成交易轉賬. 每當新地址提取 USDT 時, 都無法將提取與存款聯繫起來, 從而確保完全隱私.',

    adv_nav: '<strong class="green--text">Hide USDT</strong> 的優勢',
    adv_subtitle1: '零知識證明, 安全可靠',
    adv_content1: '利用先進的零知識證明, 通過TRON去中心化匿名交易網絡, 實現多種資產匿名交易',
    adv_subtitle2: '即時交易',
    adv_content2: '基於波場TRON網絡特有的DPOS共識機制, 匿名交易轉賬可以實現快速到賬',
    adv_subtitle3: '無法追踪, 保護隱私',
    adv_content3: '隱匿所有鏈上操作的具體信息, 攻擊者無法將區塊鏈上的信息與特定用戶關聯起來',
    adv_subtitle4: '交互簡單, 體驗流暢',
    adv_content4: '用戶賬戶信息簡潔, 可快速的轉賬和查看交易記錄',

    help_nav: '<strong class="green--text"> Hide USDT </strong> 常見問題',
    help_title1: 'Hide USDT 是什麼? ',
    help_content1: 'Hide USDT 是一個利用零知識證明對USDT TRC(20)網絡資產實現匿名轉賬交易的平台.',
    help_title2: '如何使用 Hide USDT?',
    help_content2: '使用Hide USDT平台進行匿名轉賬交易只需要3步: 1. 存入資產, 同時保存好取款需要使用到的憑證和資產存放的地址. 2. 等待一段時間(時間越長,隱匿性越高). 3. 通過對應的憑證和資產存放地址校驗, 證明資產所有權即可發起匿名轉賬, 把資產轉移到新的接收者.',
    help_title3: 'Hide USDT 如何保證匿名?',
    help_content3: 'Hide USDT 使用零知識證明算法, 用戶轉賬賬戶全部是匿名賬戶, 匿名賬戶鏈上不可查, 保證了用戶轉賬的匿名性, 實現了資產轉賬的匿名化.',
    help_title4: 'Hide USDT 轉賬的金額可否隱藏?',
    help_content4: 'Hide USDT 轉賬使用的是匿名賬戶信息, 匿名賬戶到匿名賬戶轉賬完全匿名, 但公開賬戶與其轉賬的數量鏈上是可查的, 為了進一步保證匿名性, 建議用戶分批操作轉賬並且間隔操作.',

    contact_nav: '聯繫我們',
    contact_title: '郵箱',
    contact_msg: '內容',
    contact_mailerror: '郵箱錯誤',
    contact_msgerror: '內容長度 1-500 字符'
};