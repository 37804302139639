import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from  './plugins/vue-i18n'
import qs from 'qs'
import VueClipboard from 'vue-clipboard2'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.qs =qs

Vue.prototype.$checkCookie = function(key) {
  const cookieValue = this.$cookies.get(key)
  return cookieValue != null
}

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
