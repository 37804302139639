import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout/Main.vue'),
    meta: { requiresAuth: true},
    children: [
      {
        path: '/',
        component: () => import('@/views/Index.vue'),
        meta: { requiresAuth: true}
      },
      {
        path: 'introduction',
        component: () => import('@/views/Introduction.vue'),
        meta: { requiresAuth: true}
      },
      {
        path: 'features',
        component: () => import('@/views/Features.vue'),
        meta: { requiresAuth: true}
      },
      {
        path: 'faq',
        component: () => import('@/views/Faq.vue'),
        meta: { requiresAuth: true}
      },
      {
        path: 'contact',
        component: () => import('@/views/Contact.vue'),
        meta: { requiresAuth: true}
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = Vue.prototype.$checkCookie('hidecoin_session')
  
  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})

export default router
