export const m ={
    head_nav1: '匿名交易',
    head_nav2: '介绍',
    head_nav3: '优势',
    head_nav4: '问题',
    head_nav5: '联系我们',
    head_nav6: '实现原理',

    index_tab1: '存款',
    index_tab2: '取款',
    index_deposit_label1: '存款方式',
    index_deposit_label2: '存款凭证',
    index_deposit_label3: '收款地址',
    index_deposit_hit1: '请先生成存款凭证',
    index_deposit_btn: '生成存款凭证',
    index_tips: '温馨提示',
    index_tip1: '1. 单笔交易最低限额 ',
    index_tip2: '2. 单笔交易默认手续费为 ',
    index_tip3: '3. 一个收款地址只接受一笔存款，对应生成一个存款凭证，请妥善保存',
    index_tip4: '最低 18 U',

    index_withdraw_label1: '取款方式',
    index_withdraw_label2: '存款凭证',
    index_withdraw_label3: '二维码存款地址',
    index_withdraw_label4: '取款金额',
    index_withdraw_label5: '接收地址',
    index_withdraw_hit1: '输入存款凭证',
    index_withdraw_hit2: '输入二维码存款地址',
    index_withdraw_hit3: '建议分批多次取出',
    index_withdraw_hit4: '到账金额',
    index_withdraw_hit5: '取款金额',
    index_withdraw_hit6: '燃料费',
    index_withdraw_hit7: '输入接收地址',
    index_withdraw_hit8: '可取金额 ',
    index_withdraw_btn: '我要取款',

    error_withdrawid: '存款凭证错误',
    error_depositaddr: '二维码存款地址错误',
    error_amount: '取款金额需大于零,高于燃料费但不高于可取金额',
    error_withdrawaddr: '收款地址格式错误',
    success_withdrawok: '取款成功',

    intro_nav1: '<strong class="green--text">Hide USDT</strong> 如何工作',
    intro_nav1_subtitle1: '存款',
    intro_nav1_content1: '用户生成一对随机密钥凭证(PIN)和存款地址(凭证和地址为一一对应关系), 并存入USDT(TRC20), 同时将凭证的哈希通过匿名提交给TRON的智能合约.',
    intro_nav1_subtitle2: '等待',
    intro_nav1_content2: '存款后, 用户应该等待一段时间才能提款，这里我们建议分多笔把资金分散取出，以改善他们的隐私.',
    intro_nav1_subtitle3: '取款',
    intro_nav1_content3: '用户提交存款凭证和对应的存款地址, 有效证明资金归属权, 然后匿名合约会通过TRC20将资金转移给指定的接收者.',
    intro_nav2: '<strong class="green--text">Hide USDT</strong> 如何实现隐私',
    intro_nav2_content1: 'Hide USDT 通过打破源地址和目标地址之间的链上转账关系接来提高交易隐私. 它使用零知识证明, 通过TRON去中心化匿名交易网络, 接受可以通过不同地址提取的 USDT(TRC20) 存款. 为了保护隐私, 使用TRON网络持有的DPOS共识机制, 匿名完成交易转账. 每当新地址提取 USDT 时, 都无法将提取与存款联系起来, 从而确保完全隐私.',

    adv_nav: '<strong class="green--text">Hide USDT</strong> 的优势',
    adv_subtitle1: '零知识证明, 安全可靠',
    adv_content1: '利用先进的零知识证明, 通过TRON去中心化匿名交易网络, 实现多种资产匿名交易',
    adv_subtitle2: '即时交易',
    adv_content2: '基于波场TRON网络特有的DPOS共识机制, 匿名交易转账可以实现快速到账',
    adv_subtitle3: '无法追踪, 保护隐私',
    adv_content3: '隐匿所有链上操作的具体信息, 攻击者无法将区块链上的信息与特定用户关联起来',
    adv_subtitle4: '交互简单, 体验流畅',
    adv_content4: '用户账户信息简洁, 可快速的转账和查看交易记录',

    help_nav: '<strong class="green--text"> Hide USDT </strong> 常见问题',
    help_title1: 'Hide USDT 是什么? ',
    help_content1: 'Hide USDT 是一个利用零知识证明对USDT TRC(20)网络资产实现匿名转账交易的平台.',
    help_title2: '如何使用 Hide USDT?',
    help_content2: '使用Hide USDT平台进行匿名转账交易只需要3步: 1. 存入资产, 同时保存好取款需要使用到的凭证和资产存放的地址. 2. 等待一段时间(时间越长,隐匿性越高). 3. 通过对应的凭证和资产存放地址校验, 证明资产所有权即可发起匿名转账, 把资产转移到新的接收者.',
    help_title3: 'Hide USDT 如何保证匿名?',
    help_content3: 'Hide USDT 使用零知识证明算法, 用户转账账户全部是匿名账户, 匿名账户链上不可查, 保证了用户转账的匿名性, 实现了资产转账的匿名化.',
    help_title4: 'Hide USDT 转账的金额可否隐藏?',
    help_content4: 'Hide USDT 转账使用的是匿名账户信息, 匿名账户到匿名账户转账完全匿名, 但公开账户与其转账的数量链上是可查的, 为了进一步保证匿名性, 建议用户分批操作转账并且间隔操作.',

    contact_nav: '联系我们',
    contact_title: '邮箱',
    contact_msg: '内容',
    contact_mailerror: '邮箱错误',
    contact_msgerror: '内容长度 1-500 字符'
};