import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: localStorage.getItem('Lang') || 'en',
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'en': require('../locale/en'),
        'ru': require('../locale/ru'),
        'ja': require('../locale/ja'),
        'zhHans': require('../locale/zhHans'),
        'zhHant': require('../locale/zhHant')
    }
})

export default i18n